import { required, email, sameAs } from "vuelidate/lib/validators";

export const dataCollectionContactDetails = {
  title: { required },
  firstName: { required },
  lastName: { required },
  email: { required, email },
  emailConfirmation: {
    required,
    email,
    sameAsEmail: sameAs("email"),
  },
  phone: { required },
};

export const dataCollectionContactMethods = {
  leadSource: { required },
  selectedTandCCheckbox: {
    required,
    sameAs: sameAs(() => true), // add this line in validation
  },
};

export const dataCollectionDonation = function (prize) {
  if (prize && prize !== "0")
    return {
      prize: {
        required,
      },
      paymentMethodId: {
        required,
      },
    };
  return {
    prize: {
      required,
    },
  };
};

export const dataCollectionSource = {
  selectedSource: {
    required,
  },
};

export const dataCollectionHospice = {
  selectedHospice: {
    required,
  },
};

export const dataCollectionKeepInTouch = {
  selectedContactMethods: { required },
  selectedHospiceContactMethods: { required },
};

export const dataCollectionLocation = {
  addressLine1: {
    required,
  },
  locality: {
    required,
  },
};

export const collectTrees = {
  products: {
    required,
  },
};

export const orderConfirmationAddressInformation = {
  addressLine1: { required },
  city: { required },
};

export const isValidPostCode = (postCode) =>
  /^[A-Z]{1,2}[0-9][0-9A-Z]?\s?[0-9][A-Z]{2}$/i.test(postCode);
export const nameField = (name) =>
  /^(?!.*[0-9$&+:;=?@#\\|<>\\.^*()%!]).*$/i.test(name);
